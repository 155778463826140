import { Button ,  Modal , Row,Col,Typography } from "antd";
import React from 'react';
import RevenueIcon from './../../images/revenue.png';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";
import Icon from '@ant-design/icons';
import { getDateMonthYearInReadable } from "../../utils/date.utils";
import { checkActionAllowed, isValid , isValidArray} from "../../utils/utilities";
import './revenue.style.scss';
import { SHOW_REVENUE_IN_AGENT_CLIENT } from "../../utils/constant";
import { fetchTripSheet } from "../../actions/reports.action";
import { roundNumber, convertToCurrencyNumber } from "../../utils/math.utils";

const{
    Text
} = Typography;

const renderAgents=(agents)=>{
    let agentsDivs=[],revenue=0,totalSeatCount=0, netAmount = 0;
    if(isValidArray(agents)){
        for (let index = 0; index < agents.length; index++) {
            const record = agents[index];
            revenue = revenue + record.fare+record.canellationPenality-(record.agentCommission-record.tdsAgentCommission);
                if (record.privilege != 'apiAgentsInfo') {
                    revenue+=record.gst;
                }
                totalSeatCount = totalSeatCount + record.seatsCount;
                netAmount = netAmount+record.amount;

            agentsDivs.push(<Row gutter={[16,16]}>
                <Col span={8}>{record.agentName}</Col>
                <Col span={8}>{record.seatsCount}</Col>
                <Col span={8}>₹ {convertToCurrencyNumber(record.amount)}</Col>
           </Row>);  
        }

        return (
            <div>
                <Row gutter={[16,16]}>
                    <Col>
                        <Row className="revenueBlock">
                            <Col className="revenueHeader">
                                Revenue
                            </Col>
                            <Col className="revenueValue">
                                ₹ {convertToCurrencyNumber(roundNumber(revenue))}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div>
                {agentsDivs}
                </div>
                <Row gutter={[16,16]}>
                    <Col span={8}></Col>
                    <Col span={8} className="bookedValue">
                        {totalSeatCount}
                    </Col>
                    <Col span={8} className="bookedValue">
                        ₹ {convertToCurrencyNumber(roundNumber(netAmount))}
                    </Col>
                </Row>
            </div>
        )
        
    }else{
        return null
    }
}

export const RevenueDetails = (props) => {

    const {
        tripId    
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [revenueDetails, setRevenueDetails] = useState(null);

    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }

    const onSuccess=(data)=>{
        setRevenueDetails(data);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    if(checkActionAllowed(SHOW_REVENUE_IN_AGENT_CLIENT)){

        return (
            <div>
                    <Button 
                    onClick={async()=>{
                        setLoading(true);
                        if(isValid(tripId)){
                            await fetchTripSheet(tripId,onSuccess,onFailure);
                        }
                        showModal(true);
                    }} 
                    ghost 
                    className="open-select-seat-btn">
                        <Icon component={() => (<img src={RevenueIcon} style={{width:'23px',padding:'1px'}} />)} />
                    </Button>
                <Modal 
                    centered={true}
                    visible={isModalVisible} 
                    onOk={handleOk} 
                    onCancel={handleCancel}
                    okText="Update"
                    footer={null}
                    >
                        {isValidArray(revenueDetails)?
                            <div>
                                <Row gutter={[16,6]}>
                                    <Col>
                                        <div className="revenueTripDescription">
                                            {revenueDetails.tripDescription}
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <div className="revenueTripDate">
                                            {getDateMonthYearInReadable(revenueDetails.tripStartDate)}
                                        </div>
                                    </Col>
                                </Row>
                                {renderAgents(revenueDetails.bookingInfo)}
                            </div>
                        :
                        null
                        }
                </Modal>
            </div>
          );
    }else{
        return null;
    }
};
