import React, { useEffect, useState } from "react";
import { Row,Col , Calendar , Button , Typography} from "antd";
import './dashboard.style.scss';
import moment from 'moment';
import { addMonth, firstDayOfMonth, getAPIDateFormat, getDate, getDateMonthInReadable, getMonth, lastDayOfMonth, subtractMonth } from "../../utils/date.utils";
import { getActiveStatus, getMonthlyOccupancy } from "./dashboard.module";
import { loadingAction } from "../../actions/loading-actions";
import { useDispatch } from "react-redux";

const {Text} = Typography;


export const MonthlyOccupancyComponent = (props) => {
    const {
        
    } = props


    const [currentDate , setCurrentDate] = useState(moment());
    const [openCalenderData , setOpenCalenderData] = useState({});
    let currentMonth = getMonth(currentDate);

    const dispatch = useDispatch();

    const onPreviousClick =()=>{
        setCurrentDate(subtractMonth(currentDate,1));
    }

    const onNextClick =()=>{
        setCurrentDate(addMonth(currentDate,1));
    }

    const onSuccess=(data)=>{
        setOpenCalenderData(data);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true)
        let startDate = getAPIDateFormat(firstDayOfMonth(currentDate));
        let endDate = getAPIDateFormat(lastDayOfMonth(currentDate));
        getMonthlyOccupancy(startDate,endDate,onSuccess,onFailure);
    },[currentDate]);

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

   return(
    <div className="daily-trip-summary">
        Occupancy
        <div className="monthly-occupancy-calender">
        <Calendar 
            value={currentDate}
            fullscreen={false}
            headerRender={()=>{
                return (
                    <Row gutter={[16,16]} align="middle">
                        <Col>
                            <Button type="link" className="previous-calender-btn" onClick={onPreviousClick}>
                                <Text className="normal-txt">Previous</Text>
                            </Button>
                        </Col>
                        <Col>
                            <Text strong>{getDateMonthInReadable(currentDate)}</Text>
                        </Col> 
                        <Col>
                            <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                <Text className="normal-txt">Next</Text>
                            </Button>
                        </Col>
                    </Row>
                    )              
                    }}
                    dateFullCellRender={(date)=>{
                        let day = getDate(date);
                        let month = getMonth(date);
                        if(month===currentMonth){
                            let apidate = getAPIDateFormat(date);
                            let {
                                className,
                                styles,
                                onlyDateClassName,
                                confirmedSeats
                            } = getActiveStatus(openCalenderData,apidate);
                            
                            return (
                                <div 
                                    title={`Confirmed Seats:${confirmedSeats}`} 
                                    className={`current-date ${className}`} 
                                    style={styles}
                                >
                                        <div className={`${onlyDateClassName}`}>{day}</div>
                                </div>
                                )
                        }else{
                            return (
                                <div className={`next-month-date`}>
                                    <div>{day}</div>
                                </div>
                                )
                            }
                        }}
                        mode="month"
                        onSelect={(date)=>{

                    }}
                />
        </div>
    </div>
   )
}


